.maxbox {
  width: 1300px;
  max-width: 1300px;
  margin: 0 auto;
  .box {
    display: flex;
    justify-content: space-between;
    margin-top: 92px;
  }
  .title_txt {
    font-weight: 900;
    font-size: 44px;
    color: rgba(0, 0, 0, 0.6);
  }
  .title_txt2 {
    margin-top: 17px;
    font-size: 34px;
    color: rgba(0, 0, 0, 0.6);
  }
  .title_txt3 {
    margin-top: 20px;
    width: 400px;
    font-weight: 500;
    font-size: 30px;
    color: rgba(0, 0, 0, 0.6);
  }
  .title_txt_bt {
    display: inline-block;
    padding: 21px 80px;
    background: #7992e0;
    border-radius: 100px 100px 100px 100px;
    border: 1px solid #a0b6ef;
    margin-top: 113px;
    font-weight: 500;
    font-size: 24px;
    color: #fcfcfc;
  }
  .zd_Txt {
    color: #a0b6ef;
    font-size: 64px;
  }
  .banner_box {
    width: 505px;
    .banner {
      width: 505px;
      height: 679px;
    }
  }
  .content_box {
    text-align: center;
  }
  .text_title {
    position: relative;
    font-size: 44px;
    color: #000000;
  }
  .text_title::before {
    content: "";
    position: absolute;
    background-color: #7992e0;
    bottom: 4px;
    left: 550px;
    width: 200px;
    height: 6px; /* 调整线条的粗细 */
    font-size: 44px;
    color: #000000;
  }
  .box_list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #e3e8f5;
      box-shadow: -1px 1px 1px 0px rgba(4, 74, 255, 0.5);
      border-radius: 16px 16px 16px 16px;
      padding: 0 37px 83px;
      .top_Txt {
        font-size: 32px;
        color: #202430;
      }
      .bottom_Txt {
        font-size: 26px;
        color: #999999;
      }
      .box_img {
        width: 297px;
        height: 254px;
      }
    }
  }
  .box_list2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 74px;
    .line {
      width: 203px;
      border-bottom: 2px dashed #7992e0; /* 设置虚线边框 */
    }
    .box2 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      .top_Txt {
        font-size: 32px;
        color: #202430;
      }
      .bottom_Txt {
        font-size: 26px;
        color: #999999;
      }
      .box_img {
        width: 160px;
        height: 160px;
      }
      .box_img2 {
        position: absolute;
        top: 43px;
        left: 128px;
        width: 52px;
        height: 74px;
      }
    }
    .box3 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      .top_Txt {
        font-size: 32px;
        color: #202430;
      }
      .bottom_Txt {
        font-size: 26px;
        color: #999999;
      }
      .box_img {
        width: 160px;
        height: 160px;
      }
      .box_img2 {
        position: absolute;
        top: 43px;
        left: 40px;
        width: 81px;
        height: 74px;
      }
    }
    .box4 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      .top_Txt {
        font-size: 32px;
        color: #202430;
      }
      .bottom_Txt {
        font-size: 26px;
        color: #999999;
      }
      .box_img {
        width: 160px;
        height: 160px;
      }
      .box_img2 {
        position: absolute;
        top: 43px;
        left: 72px;
        width: 78px;
        height: 74px;
      }
    }
  }
  .box_list3 {
    text-align: left;
    margin-top: 74px;
    .text_top {
      font-size: 32px;
      color: #000000;
    }
    .text_bottom {
      font-size: 26px;
      color: #999999;
      margin-top: 12px;
    }
  }
  .text_title2 {
    position: relative;
    font-size: 44px;
    color: #000000;
    margin-top: 167px;
  }
  .text_title2::before {
    content: "";
    position: absolute;
    background-color: #7992e0;
    bottom: 4px;
    left: 580px;
    width: 150px;
    height: 6px; /* 调整线条的粗细 */
    font-size: 44px;
    color: #000000;
  }
}
