.maxboxx {
  background-image: url("../../resources/images/five_bg.png");
  background-repeat: no-repeat; /* 不重复图像 */
  background-size: cover; /* 图像覆盖整个容器 */
  background-position: center; /* 图像在容器中居中 */
  background-attachment: scroll; /* 背景随内容滚动 */
  height: 1000px;
}
.maxbox {
  width: 1300px;
  max-width: 1300px;
  margin: 0 auto;
  .box_five {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 100px;
    .zg_txt_five {
      font-size: 90px;
      color: #ffffff;
      .zgpttxt {
        font-size: 90px;
        color: #fff134;
      }
    }
    .bottom_txt {
      font-size: 54px;
      color: #f6f6f6;
    }
  }
}
.content_box {
  width: 1300px;
  max-width: 1300px;
  margin: 0 auto;
  text-align: center;
  padding-top: 110px;
  .content_1 {
    .content_title {
      font-size: 58px;

      .szm_txt {
        color: #ff6600;
      }
    }
    .text-with-lines {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 38px;
      color: #3d3d3f;
    }

    .text-with-lines::before,
    .text-with-lines::after {
      content: "";
      flex: 0 0 10%; /* 控制线条长度 */
      border-bottom: 1px solid #000;
      margin: 0 10px;
    }
    .content_nr {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .content_box1 {
        background: rgba(252, 108, 44, 0.02);
        border-radius: 9px 9px 9px 9px;
        border: 1px solid rgba(252, 108, 44, 0.5);
        width: 356px;
        margin-top: 77px;
        .list_icon {
          width: 226px;
          height: 245px;
        }
        .bottom_txt {
          background: rgba(252, 108, 44, 0.05);
          border-radius: 0px 0px 9px 9px;
          padding-top: 22px;
          padding-bottom: 23px;
          .ppq {
            font-size: 44px;
            color: #ff6600;
          }
          .ppq_bottom {
            font-size: 28px;
            color: #999999;
          }
        }
      }
    }
  }
  .content_2 {
    margin-top: 188px;
    .content_title {
      font-size: 58px;

      .szm_txt {
        color: #ff6600;
      }
    }
    .text-with-lines {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 38px;
      color: #3d3d3f;
    }

    .text-with-lines::before,
    .text-with-lines::after {
      content: "";
      flex: 0 0 10%; /* 控制线条长度 */
      border-bottom: 1px solid #000;
      margin: 0 10px;
    }
    .content_nr {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .content_box1 {
        border: 1px solid rgba(252, 108, 44, 0.5);
        width: 356px;
        margin-top: 77px;
        border-top: none;
        border-bottom: none;
        .title_txt {
          font-size: 32px;
          color: #3d3d3f;
          margin-bottom: 23px;
        }
        .list_icon {
          width: 105px;
          height: 105px;
        }
        .bottom_txt {
          border-radius: 0px 0px 9px 9px;
          padding-top: 22px;
          padding-bottom: 23px;
          text-align: center;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          .ppq_bottom {
            max-width: 184px;
            width: 184px;
            font-size: 20px;
            color: #3d3d3f;
          }
        }
      }
    }
  }
  .content_3 {
    margin-top: 188px;
    .content_title {
      font-size: 58px;

      .szm_txt {
        color: #ff6600;
      }
    }
    .text-with-lines {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 38px;
      color: #3d3d3f;
    }

    .text-with-lines::before,
    .text-with-lines::after {
      content: "";
      flex: 0 0 10%; /* 控制线条长度 */
      border-bottom: 1px solid #000;
      margin: 0 10px;
    }
    .content_nr {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .content_box1 {
        width: 356px;
        margin-top: 77px;
        .title_txt {
          font-size: 32px;
          color: #3d3d3f;
          margin-bottom: 23px;
        }
        .list_icon {
          width: 105px;
          height: 105px;
          background: #79e28a;
          border-radius: 15px 15px 15px 15px;
          padding: 21px 29px;
        }
        .list_icon2 {
          width: 125px;
          height: 105px;
          background: #60daff;
          border-radius: 15px 15px 15px 15px;
          padding: 21px 29px;
        }
        .list_icon3 {
          width: 125px;
          height: 105px;
          background: #f37168;
          border-radius: 15px 15px 15px 15px;
          padding: 21px 29px;
        }
        .bottom_txt {
          border-radius: 0px 0px 9px 9px;
          text-align: center;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .title_txt2 {
            font-size: 32px;
            color: #3d3d3f;
            padding-top: 14px;
          }
          .ppq_bottom2 {
            font-size: 26px;
            color: #3d3d3f;
          }
        }
      }
    }
  }
}
.content_4 {
  margin-top: 188px;
  background-image: url("../../resources/images/five_bottom_bg.png");
  background-repeat: no-repeat; /* 不重复图像 */
  background-size: cover; /* 图像覆盖整个容器 */
  background-position: center; /* 图像在容器中居中 */
  background-attachment: scroll; /* 背景随内容滚动 */
  padding: 90px;
  height: 1000px;
  .content_title {
    font-size: 58px;
    text-align: center;
    color: #ffffff;
    .szm_txt {
      color: #ff6600;
    }
  }
  .text-with-lines2 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 38px;
    color: #ffffff;
  }

  .text-with-lines2::before,
  .text-with-lines2::after {
    content: "";
    flex: 0 0 10%; /* 控制线条长度 */
    border-bottom: 1px solid #ffffff;
    margin: 0 10px;
  }
  .content_nr {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .content_box1 {
      width: 356px;
      margin-top: 77px;
      background-image: url("../../resources/images/five_img_bottom.png");
      background-repeat: no-repeat; /* 不重复图像 */
      background-size: cover; /* 图像覆盖整个容器 */
      background-position: center; /* 图像在容器中居中 */
      background-attachment: scroll; /* 背景随内容滚动 */
      padding: 110px 0;
      .title_txt {
        font-size: 32px;
        color: #3d3d3f;
        margin-bottom: 23px;
      }
      .list_icon {
        width: 105px;
        height: 105px;
        background: #79e28a;
        border-radius: 15px 15px 15px 15px;
        padding: 21px 29px;
      }
      .list_icon2 {
        width: 125px;
        height: 105px;
        background: #60daff;
        border-radius: 15px 15px 15px 15px;
        padding: 21px 29px;
      }
      .list_icon3 {
        width: 125px;
        height: 105px;
        background: #f37168;
        border-radius: 15px 15px 15px 15px;
        padding: 21px 29px;
      }
      .bottom_txt {
        border-radius: 0px 0px 9px 9px;
        text-align: center;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .title_txt3 {
          font-size: 24px;
          color: #000000;
          padding-top: 14px;
        }
        .ppq_bottom3 {
          font-size: 20px;
          color: #999999;
          padding-top: 10px;
        }
        .bt_an {
          background: #ff6600;
          border-radius: 8px 8px 8px 8px;
          font-size: 22px;
          color: #ffffff;
          padding: 0px 10px;
          margin-top: 10px;
        }
      }
    }
  }
}
