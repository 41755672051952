.maxboxxsix {
  background-image: url("../../resources/images/six_bg.png");
  background-repeat: no-repeat; /* 不重复图像 */
  background-size: cover; /* 图像覆盖整个容器 */
  background-position: center; /* 图像在容器中居中 */
  background-attachment: scroll; /* 背景随内容滚动 */
  height: 800px;
}
.maxbox {
  width: 1300px;
  max-width: 1300px;
  margin: 0 auto;
  .box_six {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 100px;
    .zg_txt_six {
      font-size: 65px;
      color: #ffffff;
      margin-top: 150px;
    }
    .bottom_txt {
      font-size: 22px;
      color: #ffffff;
      margin-top: 50px;
    }
    .bottom_txt2 {
      font-size: 22px;
      color: #ffffff;
    }
    .bt_ljty {
      background: #69acff;
      border-radius: 198px 198px 198px 198px;
      font-size: 22px;
      color: #ffffff;
      padding: 10px 35px;
      margin-top: 43px;
    }
  }
}
.conten_box {
  width: 100%;
  text-align: center;
  margin: 0 auto;
  .title_txt {
    font-size: 44px;
    color: #0e2336;
    margin-top: 120px;
  }
  .title_bottom_icon {
    width: 161px;
    height: 5px;
  }
  .content_txt {
    font-size: 22px;
    color: #616467;
    margin-top: 19px;
    max-width: 1200px;
  }
  .neirong_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    text-align: center;
    margin: 0 auto;
    max-width: 1200px;
  }
  .list_box {
  }
  .jj1_bg {
    width: 472px;
    height: 306px;
  }
}
