@import 'rc-steps/assets/index.css';

@font-face {
  font-family: DS-Digital-Bold;
  src: url(../font/SourceHanSansCNRegular.ttf) format("truetype");
}
.headButtom{
  border-bottom: 1px solid #dee2e6;
}
.p-t-58{
  padding-top: 58px !important;
}
.serviceContainer{
  width: 100%;
  height:580px;
}
.serviceContainerWarp{
  margin-top: 20px;
  width: 1200px;
  height: 600px;
  margin-bottom:80px;
}
.service-title{
   font-size: 48px;
   color:'#333333';
   padding-top:68px;
   letter-spacing:5px; 
   font-weight: 600;
}
.image-contail{
  position:absolute;
  left: 359px;
  top: 143px;
}
.title-color{
  color:white !important
}

.ft-60{
  font-size:60px !important;
} 
.mr-154{
  margin-right: 154px !important;
}
.m-t-20{
  margin-top: 20px;
}
.ft-30{
 font-size: 30px;
}
.icon-title{
  color:'#333333';
  margin-top:10px;
}
.icon-text{
  font-size: 20px;
  font-weight: 400;
  color: #333333;
  text-align: center;
}
.mx-200{
  max-width: 200px;
}
.w-200{
  width: 200px;
}
.w-250{
  max-width: 250px;
}
.stepContainer{
  width: 100%;
  height: 700px;
  background: #297BD9;
}
.step-title{
  font-size: 48px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 48px;
  letter-spacing: 5px;
  padding-top:70px;
}
.steps{
  width: 1300px;
  padding-top: 35px;
}
.steps-title{
font-size: 30px;
font-weight: 500;
color: #FFFFFF;
letter-spacing: 3px;
}
.rc-steps-item-content{
  width: 350px !important;
  text-align:left !important;
}

.questionContailner{
  min-height: 700px;
  width: 100%;
}
.question-title{
  font-size: 48px;
  font-weight: 600;
  color: #333333;
  line-height: 48px;
  letter-spacing: 5px;
  padding-top:70px;
}
.question-t{
  font-size: 36px;
  font-weight: 500;
  color: #333333;
  line-height: 50px;
}
.question-a{
 font-size: 24px;
 font-weight: 400;
 color: #999999;
 line-height: 33px;
}
.mb-40{
  margin-bottom: 60px ;
}
.steps-d{
color:#66AFFB;
font-size: 24px;
font-weight: 400;
line-height: 33px;
}
.connect{
  width:100%;
  height: 450px;
  background-color:#eef0f3 ;
}
.look-q{
font-size: 36px;
font-weight: 500;
color: #297BD9;
line-height: 50px;
margin-left: 53px;
}
.question-h{
border: 1px solid #979797;
margin-top: 58px;
}
.qusetion-icon{
  margin-top: 104px;
  margin-left:53px;
  margin-right:120px;
}
.quesiton-c{
  font-size: 24px;
  font-weight: 400;
  color: #333333;
  line-height: 33px;
}
.line{
 font-size: 20px;
 font-weight: 400;
 color: #297BD9;
 line-height: 28px;
}
.buttom{
  width: 100%;
  height: 468px;
  background: #40444A;
}
.buttom-warp{
  margin-top:61px;
  width: 1100px;
  height: auto;
  color:#D8D8D8;
}
.buttom-item{
  width: 520px;
}
.time{
color: #301a1a;
}
.equipment{
  display: block;
  border: 1px solid;
  border-radius: 5px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  width:180px;
}
.mt-30{
  margin-top:30px;
}
.download{
font-size: 20px;
font-weight: 500;
color: #FFFFFF;
line-height: 33px;
margin-top: 10px;
}
.mt-10{
  margin-top: 10px;
}
.ft-24{
  font-size:24px;
}
.ft-20{
  font-size: 20px;
}
.ft-14{
  font-size: 14px;
}
.ft-40{
  font-size: 40px !important ;
}
.ft-70{
  font-size: 70px;
}

.mxImage-contail{
  position:absolute;
  left: 359px;
  top: 143px;
}
.mx-title-color{
  color:#4051A1
}
.mx-title-1{
font-size: 28px;
font-family: SourceHanSansCNRegular,serif;
font-weight: 400;
color: #333333;
line-height: 118px;
letter-spacing:8px;
}
.mx-banner-button{
width: 400px;
height: 52px;
background: #4051A1;
text-align: center;
}
.mx-banner-button span{
  width: 196px;
  height: 23px;
  font-size: 24px;
  font-family: SourceHanSansCNRegular,serif;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 52px;
}
.mt-82{
  margin-top:82px;
}
.mb-82{
  margin-bottom:82px;
}
.mx-center-Warp-title{
font-size: 44px;
font-weight: bold;
color: #333333;
line-height: 31px;
}
.advantage-warp{
 width: 1200px;
}
.advantage-item{
  width: 277px;
  height: 329px;
  background: #F7F7F7;
}
.advantage-item img{
  width:163px;
  height:188px;
  margin-top: -47px;
}
.advantage-item hr{
  border:1.5px solid  #4051A1;
  opacity: 1;
  margin-top: 30px ;
  width:30px;
}
.advantage-item-title{
  font-size: 30px;
  font-weight: 400 ; 
  color: #333333;
}
.advantage-item-title-d{
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #696969;
  text-align: center;
}
.mx-image-contail{
  position:absolute;
  left: 359px;
  top: 143px;
}
.mx-step-warp{
  max-width:1000;
  height: auto;
}
.mx-step-title-Warp{
  font-weight: bold;
  color:white;
  line-height: 31px;
  font-size:44px;
}
.step-hr{
  border:1.5px solid white;
  background-color: white;
  opacity: 1;
  margin-top: 30px ;
  width:83px;
}
.mx-step-context{
  max-width:1100px;
  height: auto;
}
.text{
  border:1px solid red
}
.step-num{
font-size: 71px;
font-weight: bold;
color: #FFFFFF;
line-height: 65px;
}
.step-num-t{
font-size: 34px;
font-weight: bold;
color: #FFFFFF;
line-height: 31px;
margin-left: 10px;
white-space:nowrap;
}
.step-num-s{
  font-size: 26px;
  color: #FFFFFF;
  /* white-space:nowrap; */
  display: block;
  margin-top: 50px;
  max-width: 150%;

}
.image-step-contail{
  position:relative;
  left:-210px;
  top:60px
}
.image-step-right-contail{
  position:relative;
  top:60px;
  right: -270px;
}
.image-step-right-contails{
  position:relative;
  top:60px;
  right: -320px;
}
.image-step-right-contaild{
  position:relative;
  top:60px;
  right: -210px;
}
.step-num-right-s{
  font-size: 26px;
  color: #FFFFFF;
  display: block;
  margin-top: 50px;
  max-width: 120%;
}
.mx-banner-image-contail{
  position:absolute;
  left: 15%;
  top: 143px;
}
.en-banner-image-contail{
  position:absolute;
  left: 20%;
  top: 143px;
}
.w-110{
  max-width: 1100px !important;
}
.mx-pb-title{
font-size: 36px;
font-weight: 400;
color: #333333;
line-height: 36px;

}
.mx-pb-d{
font-size: 24px;
font-weight: 400;
color: #626262;
line-height: 33px;
padding-top:5px;
word-wrap: break-word;
word-break: normal;
width:800px
}
.pb-item{
  margin-left: 32px;
  padding-top:10px;
  max-width: 800px;
}
.mt38{
  margin-top:38px;
}
.mx-bottom{
  /* height:232px; */
  background: #2A2A2A;
}
.mx-buttom-warp{
  margin-top:50px;
  width: 1100px;
  height: auto;
  color:#D8D8D8;
  margin-bottom: 50px;
  
}
.ft-22{
  font-size :22px;
}
.ft-16{
  font-size: 16px;
}
.bottom-item-value{
 word-wrap: break-word;
 word-break: normal;
 max-width: 300px;
 min-width: 200px;
 margin-left: 5px;
}
.en-button{
  background-color: rgba(0, 0, 0, 0) !important;
  border: 1px solid;
}

.en-banner-button{
  width: 240px;
  height: 52px;
  background: #4051A1;
  text-align: center;
  }
  .en-banner-button span{
    width: 196px;
    height: 23px;
    font-size: 24px;
    font-family: SourceHanSansCNRegular,serif;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 52px;
  }
  .en-advantage{
    background: #F7F7F7;
    width: 100%;
  }
  .en-advantage .warp{
    margin: 43px 0 43px 0;
    width: 1400px;
  }
  .warp>div{
    width: 300px;
    height: 174px;
    background: #FFFFFF;
    padding-left: 20px;
  }
  .advantage-t{
    font-size: 25px;
    display: block;
    font-weight: 400;
    padding:0px 20px 0px 20px
  }
  .advantage-d{
    word-wrap: break-word;
    word-break: normal;
    font-size: 16px;
    display: block;
    color: #696969;
    font-weight: 400;
    padding:0px 20px 0px 20px
  }
  .en-life .life-warp{
    margin-top: 43px;
    width: 1200px;
    margin-bottom: 43px;
  }
.life-warp label{
    font-size: 44px;
    font-weight: bold;
    color: #333333;
    line-height: 31px;
  }
  .en-life .life-warp div .item{
    width: 375px;
    height: 418px;
    background: #FFFFFF;
    box-shadow: 1px 2px 27px 0px rgba(174, 174, 174, 0.26);
  }
  .en-life-s{
    height: 7px;
    width: 149px;
  }
.lift-ct{
  margin-top: 67px;
  margin-bottom: 50px;
}
.life-d-warp{
  padding:15px 0px 0px 10px;
}
.en-step{
padding-top: 75px;
width: 100%;
min-height: 687px;
background: #1358B9;
opacity: 0.83;
}
.step-item{
  width: 304px;
  text-align: center;
}
.en-step-warp{
  width: 1100px;
}
.ft-34{
font-size:34px !important;
}
.ft-26{
  font-size: 26px;
}
.pb-warp{
  width: 1100px;
  margin-top: 75px;
}
.pb-warp label{
  font-size: 44px;
  font-weight: bold;
  color: #333333;
  line-height: 31px;
}
.en-step-num{
width: 77px;
height: 35px;
background: #F29360;
font-size: 26px;
font-weight: 400;
color: #FFFFFF;
line-height: 31px;
text-align: center;
}
.en-icon{
  width: 45px;
  height: 41px;
  margin-top:39px;
}
.en-setp-d{
font-size: 36px;
font-weight: 400;
color: #333333;
line-height: 36px;
}
.en-setp-t{
  font-size: 24px;
  font-weight: 400;
  color: #626262;
  line-height: 33px;
}
.h30{
  height:30px;
}


